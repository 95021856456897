<template>
<div>
	<base-header class="header pb-8 pt-7 pt-lg-8 d-flex align-items-center">
		<!-- Mask -->
		<span class="mask bg-gradient-success opacity-8"></span>
		<!-- Header container -->
		<div class="container-fluid align-items-center">
			<div class="row">
				<div class="col">
					<h1 class="display-2 text-white">Settings</h1>
					<p class="text-white mt-0 mb-0">Manage settings here</p>
				</div>
			</div>
		</div>
	</base-header>
</div>
</template>
<script>
export default {
	name: 'settings',
	data() {
		return {

		}
	}
};
</script>
<style>
</style>
