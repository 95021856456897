<template>
	<div>
		<div class="d-flex justify-content-between my-4 mx-3">
			<h6 class="heading-small text-muted">Personal information</h6>
		</div>
		<div class="pl-lg-4">
			<div class="row mb-3">
				<div class="col-lg-12 form-group">
					<label class="form-control-label"
						>Profile picture (Optional)</label
					>
					<input class="form-control" type="file" @change="onFileChange"  />
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6">
					<base-input
						label="Name"
						input-classes="form-control"
						v-model="data.name"
					/>
				</div>
				<div class="col-lg-6">
					<base-input
						label="Mobile Number"
						input-classes="form-control"
						v-model="data.mobile_number"
						type="text"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6">
					<base-input
						label="Email ID"
						input-classes="form-control"
						v-model="data.email"
						type="email"
					/>
				</div>
                <div class="col-lg-6">
					<base-input
						label="Date of birth"
						input-classes="form-control"
						v-model="data.date_of_birth"
						type="date"
					/>
				</div>
				<div class="col-lg-12">
					<base-input
						label="Address"
						input-classes="form-control"
						v-model="data.address"
					/>
				</div>
				
                <div class="col-lg-6" v-if="executiveID===undefined">
					<base-input
						label="Password"
						input-classes="form-control"
						v-model="data.password"
						type="password"
					/>
				</div>
				<div class="col-lg-6" v-if="executiveID===undefined" >
					<base-input
						label="Retype password"
						input-classes="form-control"
						v-model="repassword"
						type="password"
					/>
				</div>
			</div>
		</div>


		<div class="button-bar text-right mt-4">
			<button class="btn btn-danger" @click.prevent="$router.back()">
				Cancel
			</button>
			<button class="btn btn-success" @click.prevent="addEditExecutive">
				Done
			</button>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>

export default {
	name: "add-executive-profile",
	props: {
		executiveID: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			displayLoader: false,
			files:[],
			profile_photo:null,
            repassword: undefined,
			data: {
				name: "",
				email: "",
				mobile_number: "",
				address: "",
				password: undefined,
				date_of_birth: "",

			},
			deleteItemIndex:-1
		};
	},
	methods: {
		onFileChange(e) {
			this.files = e.target.files || e.dataTransfer.files;
			if (!this.files.length) return;
			this.profile_photo = this.files[0].name;
		},
        fetchExecutiveList(){
			this.$store.dispatch('fetchExecutiveList').then(() => {
				this.displayLoader = false;
			}).catch((error) => {
				this.displayLoader = false;
				this.$notify({
					title: "Failed to fetch executive list",
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
					message: error.toString(),
				});
			});

		},
		addEditExecutive() {
            if(this.executiveID===undefined){
			if (this.data.password !== this.repassword) {
				this.$notify({
					title: "Passwords do not match",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
				});
				return;
			}
            this.$store.dispatch("updateExecutiveProfile", this.data)
					.then(() => {
						this.$router.back();
					}).catch((error) => {
						this.$notify({
							title: "Failed to update executive",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					}).then(() => {
						this.displayLoader = false;
					});
            }else{
                delete this.data.password;
                this.$store.dispatch("updateExecutiveProfile", this.data)
					.then(() => {
						this.$router.back();
					}).catch((error) => {
						this.$notify({
							title: "Failed to update executive",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					}).then(() => {
						this.displayLoader = false;
					});
            }																
		},
	},
	computed: {
		executiveList() {
			return this.$store.state.ExecutiveData.executiveList;
		},
	},
	watch: {

	},
	mounted() {
        this.fetchExecutiveList()
        if(this.executiveID !==undefined){
            this.$store
				.dispatch("fetchExecutiveProfile", this.executiveID)
                .then(()=>{this.data=JSON.parse(JSON.stringify(this.$store.state.ExecutiveData.executiveProfile))})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch executive profile",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
        }
	},
};
</script>
<style ></style>
