<template>
	<div>
		<div class="row">
			<div class="col">
				<h1 class="display-2">Categories</h1>
			</div>
		</div>
		<div class="row mt-4">
			<div
				class="col-lg-4 col-md-3 col-sm-3 d-flex align-items-center justify-content-between"
			>
				<!-- <base-input
					placeholder="Search"
					class="input-group-alternative"
					addon-right-icon="fas fa-search"
				>
				</base-input> -->
			</div>
			<div class="col text-right">
				<base-button
					type="button"
					@click.prevent="addCategory"
					class="btn btn-success"
				>
					Add Category
				</base-button>
			</div>
		</div>
		<div class="row">
			<div
				class="col-4 text-center p-3 m-0"
				v-for="(category, index) in categories"
				:key="index"
			>
				<div
					class="p-0 btn btn-outline-success"
					@click.prevent="editCategory(category.id)"
				>
					<div class="row align-items-center m-0">
						<div class="col-4 bg-light p-0 rounded-left">
							<img
								class="img-fluid w-100 p-sm-3 p-md-3 p-lg-4"
								:src="image"
							/>
						</div>
						<div class="col-8 p-0">{{ category.name }}</div>
					</div>
				</div>
			</div>
		</div>
		<modal
			class="modal"
			:show="modalOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="modalOpen = false"
			modal-classes=" modal-dialog-centered  modal-md"
			type="mini"
		>
			<template v-slot:header>
				<div class="row">
					<h3 class="col-sm">Edit Category</h3>
				</div>
			</template>
			<div class="mb-4">
				<label for="" class="">Name</label>
				<base-input
					placeholder="Name"
					class="input-group-alternative"
					alternative=""
					v-model="data.name"
				>
				</base-input>
				<textarea
					placeholder="Details"
					class="form-control"
					v-model="data.description"
				></textarea>
			</div>
			<template v-slot:footer>
				<base-button
					type="danger pl-4 pr-4 "
					class="mb-2 btn btn-sm"
					@click.prevent="modalOpen = false"
					>Cancel</base-button
				>
				<base-button
					type="success pl-4 pr-4"
					class="mb-2 btn btn-sm"
					@click.prevent="saveChanges"
					>Save</base-button
				>
			</template>
		</modal>
	</div>
</template>
<script>
export default {
	name: "categories",
	data() {
		return {
			image: "./img/ecommerce/others.svg",
			modalOpen: false,
			data: {
				name: '',
				description:''
			}
		};
	},
	computed: {
		categories() {
			return this.$store.state.EcommerceData.Categories.categoryList;
		},
	},
	methods: {
		addCategory() {
			this.$router.push("categories/add-category");
		},
		editCategory(id) {
			this.modalOpen = true;
			this.displayLoader = true;
			this.$store
				.dispatch("fetchCategoryDetails",id)
				.then(() => {
					this.data =JSON.parse(JSON.stringify(this.$store.state.EcommerceData.Categories.categoryDetails))
					this.displayLoader = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch category details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		saveChanges() {
			var error = undefined;
			if (this.data.name == "") {
				error = "Name is required ";
			}
			if (this.data.description == '') {
				error = "Description is required";
			}
			
			if (error) {
				this.$notify({
					title: error,
					icon: "fa fa-exclamation-triangle",
					type: "danger",
				});
				return;
			}
			this.$store
				.dispatch("setCategoryDetails", this.data)
				.then(() => {
					this.modalOpen=false
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to add category",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
		},
		fetchCategoryList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchCategoryList")
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch category list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
	},
	mounted() {
		this.fetchCategoryList();
	},
};
</script>
<style scoped>
.bg-dark {
	background-color: #002635;
}
</style>
