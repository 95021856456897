<template>
<div class="text-center">
	<img src="../assets/404.png" class="img-fluid">
	<h1 class="mt-2">Not Found!</h1>
	<a href="" @click.prevent.stop="$router.go(-1)">Go back</a><br>
	<router-link to="/dashboard">Home</router-link>
</div>
</template>

<script>
export default {
	name: 'not-found'
}
</script>

<style scoped>
div {
	font-size: 1.5rem !important;
}

h1 {
	font-size: 1.5em !important;
	color: var(--border);
}
</style>
