<template>
	<div>
		<div class="container-fluid align-items-center">
			<div class="mt-5 w-100 text-center">
				<h1 class="display-4">Executive Profile Dashboard</h1>
				<!-- <base-button icon="fa fa-download" @click.prevent="downloadReport">Download sample report</base-button> -->
			</div>

			<vue-html2pdf
				:show-layout="false"
				:float-layout="true"
				:enable-download="false"
				:preview-modal="true"
				:paginate-elements-by-height="1400"
				filename="Farmer Report"
				:pdf-quality="2"
				:manual-pagination="false"
				pdf-format="a4"
				pdf-orientation="landscape"
				pdf-content-width="800px"
				:html-to-pdf-options="{ margin: 10 }"
				ref="html2Pdf"
			>
				<!-- <section slot="pdf-content">
					<FarmerReport />
				</section> -->
			</vue-html2pdf>

			<div class="row">
				<div
					class="
						col-sm-12 col-md-12 col-lg-3 mr-7
						align-self-center
						justify-content-center
					"
				>
					<div class="btn d-block" @click.prevent="editExecutive">
						<img
							:src="$store.state.accountData.profilePicture"
							class="rounded-circle my-2"
							width="80px"
						/><br />
						<div>Executive {{ executiveProfile ? executiveProfile.name : "Unknown name" }}</div>
					</div>
				</div>

				<!-- Header cards -->
				<div
					v-for="(headerCard, cardIndex) in headerCardData"
					class="d-col-sm-12 col-md-12 col-lg-4 mr-8 "
					:key="cardIndex"
				>
					<header-card
						class="my-5 "
						:headerCardData="headerCard"
					></header-card>
				</div>

				<!-- Cards -->
				<div
					class="col-4 text-center p-4 m-0"
					v-for="(subPage, subPageIndex) in subPages"
					:key="subPageIndex + ' page'"
				>
					<div
						class="p-0 btn btn-outline-primary"
						@click.prevent="goToPage(subPage.name)"
					>
						<div class="row align-items-center m-0">
							<div class="col-3 bg-lighter p-0 rounded-left">
								<img
									class="img-fluid w-100 p-sm-1 p-md-1 p-lg-3"
									:src="subPage.image"
								/>
							</div>
							<div class="col-8 p-0">{{ subPage.name }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf';


export default {
	name: "executive-profile-dashboard",
	components: {
		VueHtml2pdf,
	},
	props: {
		executiveID: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			subPages: [
				{
					name: "Location",
					image: "./img/executive section/maps.svg",
				},
				{
					name: "Attendance",
					image: "./img/executive section/group.svg",
				},
				{
					name: "Tasks",
					image: "./img/executive section/task-completed.svg",
				},
				
			],
			headerCardData: [
				{
					title: "Farmers",
					content: "9587",
					icon: "fa fa-leaf",
					iconGradient: "gradient-green",
					changePercent: "13%",
					changePositive: true
				},

				
			],
			displayLoader: false,
		};
	},
	methods: {
		downloadReport() {
			this.$refs.html2Pdf.generatePdf();
		},
		editExecutive() {
			this.$router.push("dashboard/edit-executive");
		},
		goToPage(page) {
			this.$router.push("/executive-section/" + this.executiveID + "/dashboard/" + page.toLowerCase().replaceAll(" ", "-"));
		},
		fetchFarmerCount(){
			this.displayLoader = true;
			this.$store.dispatch('fetchFarmerCount',this.executiveID).then(() => {
				this.headerCardData[0].content=this.$store.state.ExecutiveData.farmerCount;
				this.displayLoader = false;
			}).catch((error) => {
				this.displayLoader = false;
				this.$notify({
					title: "Failed to fetch executive list",
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
					message: error.toString(),
				});
			});

		}
	},
	computed: {
		executiveProfile() {
			return this.$store.state.ExecutiveData.executiveProfile;
		},

	},
	watch :{

	},
	mounted() {	

         if(this.executiveID !==undefined){
			this.fetchFarmerCount();
			this.displayLoader = true;
            this.$store
				.dispatch("fetchExecutiveProfile", this.executiveID)
				.then(() => {
				this.displayLoader = false;
			})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch executive profile",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
        }
	},
};
</script>
<style scoped></style>
