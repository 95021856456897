<template>
	<div>
		<div class="container-fluid align-items-center">
			<div class="row mb-4">
				<div class="col">
					<h2 class="display-4">Add Category</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<label for="" class="">Name</label>
					<base-input
						placeholder="Name"
						class="input-group-alternative"
						alternative=""
						v-model="data.name"
					>
					</base-input>
				</div>
			</div>
			<div class="mt-2 row">
				<textarea
					placeholder="Details"
					class="form-control mx-3"
					v-model="data.description"
				></textarea>
			</div>
			<!-- <div class="row mt-4">
				<input
					class="mx-3"
					type="file"
					id="image-input"
					name="photos"
					accept="image/jpeg"
					multiple
				/>
			</div> -->
			<div class="d-flex justify-content-end mt-8 mb-6">
				<button class="btn btn-danger" @click.prevent="$router.back">Cancel</button>
				<button class="btn btn-success" @click.prevent="addCategory">Save</button>
			</div>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
export default {
	data() {
		return {
			displayLoader: false,
			data: {
				name: '',
				description:''
			}
		};
	},
	computed: {},
	methods: {
		addCategory() {
			var error = undefined;
			if (this.data.name == "") {
				error = "Name is required ";
			}
			if (this.data.description == '') {
				error = "Description is required";
			}
			
			if (error) {
				this.$notify({
					title: error,
					icon: "fa fa-exclamation-triangle",
					type: "danger",
				});
				return;
			}
			this.$store
				.dispatch("setCategoryDetails", this.data)
				.then(() => {
					this.$router.back()
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to add category",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
		},
	},
	mounted() {},
};
</script>
<style scoped></style>
