<template>
<div>
	<div class="row">
		<div class="col">
				<div slot="header" class="border-0">
					<div class="row align-items-center">
						<div class="col">
							<h3 class="mb-0">{{ actionString }} Item</h3>
						</div>
					</div>
				</div>

				<h6 class="heading-small text-muted mb-4">Institutional Advisories</h6>
				<div class="pl-lg-4">
					<div class="row">
						<div class="col-lg-6">
							<base-input label="Name" v-model="advisoryData.name" :disabled="viewOnly" />
						</div>
						<div class="col-lg-6">
							<base-input label="File" :disabled="viewOnly" type="file" />
						</div>
						<div class="col-12 form-group">
							<label class="form-control-label">Area</label>
							<select class="form-control" v-model="advisoryData.area" :disabled="viewOnly">
								<option value="general" selected>Generic Area</option>
								<!-- <option v-for="(land, index) in landList" :key="index" :value="land">
									{{ land }}
								</option> -->
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-12 form-group">
							<label class="form-control-label"> Details </label>
							<textarea class="form-control" rows="8" @input="watchBullets()"
								v-model="advisoryData.details" :disabled="viewOnly"></textarea>
						</div>
					</div>
				</div>

				<div class="button-bar text-right mt-4">
					<button class="btn btn-danger" v-if="!viewOnly" @click.prevent="$router.back()">Cancel</button>
					<button class="btn btn-success" @click.prevent="performAction">Done</button>
				</div>
		</div>
	</div>
</div>
</template>
<script>
export default {
	name: "add-institutional-advisories",
	props: {
		selectedCrop: Number,
		editItem: {
			type: Object,
			default: undefined
		},
		viewOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			itemData: ['', 'Generic Area', '• ', 'FILE'],
			advisoryData: {
				crop:"",
				name:"",
				area:"",
				details:"• "
			}
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {

				if (this.inputHasErrors()) {
					return;
				}

				// var invalidValue = false;
				// this.itemData.forEach((element, index) => {
				// 	this.itemData[index] = element.trim();
				// 	if (this.itemData[index] == '') {
				// 		invalidValue = true;
				// 	}
				// });

				// if (invalidValue) {
				// 	this.$notify({
				// 		title: 'Error',
				// 		icon: 'fa fa-exclamation-triangle',
				// 		type: 'danger',
				// 		message: 'Please enter valid data for all fields'
				// 	});
				// 	return;
				// }

				this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					advisoryData: this.advisoryData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						advisoryData: this.advisoryData,
						index: this.editItem.index
					};
				}

				this.$store
					.dispatch("setAdvisoriesDetails", actionObject)
					.then(() => {
						this.$router.back();
					})
					.catch(error => {
						this.$notify({
							title: "Failed to " + actionString + " nutrient",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
				
			}
		},
		inputHasErrors() {
			var invalidValue = false;

			if (this.advisoryData.name == "") {
				invalidValue = true;
			}
			if (this.advisoryData.area == "") {
				invalidValue = true;
			}
			if (this.advisoryData.details == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
		watchBullets() {
			// Fragile lol
			var str = this.advisoryData.details;
			if (str[str.length - 1] == '\n' || str.length == 0) {
				this.advisoryData.details += '• ';
			}
		},
		selectCrop() {
			this.advisoryData.crop = this.selectedCrop
		}
	},
	computed: {
		table() {
			return this.$store.state.cropData.institutionalAdvisories.tableData;
		},
		actionString() {
			if (this.viewOnly) {
				return "View";
			} else if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		},
		landList() {
			return this.$store.state.farmerDashboard.landList;
		},
	},
	watch: {
		selectedCrop() {
			this.selectCrop();
		}
	},
	mounted() {
		if(this.selectedCrop === undefined){
			this.$router.back()
		}
		if (this.editItem) {
			this.advisoryData = this.editItem.data;
		}
		this.selectCrop();
	},
};
</script>
<style scoped>
</style>
