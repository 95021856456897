<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h1 class="display-4">{{ actionString }} Feedback</h1>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <base-input
                label="Date"
                input-classes="form-control"
                type="date"
                v-model="itemData.added_date"
                :disabled="viewOnly"
              />
            </div>
            <div class="col-6"></div>
            <div class="col-lg-6 col-md-12">
              <base-input
                label="Recommendation"
                input-classes="form-control"
                type="text"
                v-model="itemData.recommendation"
                :disabled="viewOnly"
              />
            </div>
            <div class="col-lg-6 col-md-12">
              <base-input
                label="Feedback"
                input-classes="form-control"
                type="text"
                v-model="itemData.feedback"
                :disabled="viewOnly"
              />
            </div>
          </div>
          <div class="button-bar text-right mt-4">
            <button
              class="btn btn-danger"
              @click.prevent="goBackToParent"
              v-if="!viewOnly"
            >
              Cancel
            </button>
            <button class="btn btn-success" @click.prevent="performAction">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "add-fvr",
  props: {
    farmerID: {
      type: String,
      default: undefined,
    },
    editItem: {
      type: Object,
      default: undefined,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemData: {
        added_date: "",
        recommendation: "",
        feedback: "",
      },
    };
  },
  methods: {
    performAction() {
      if (!this.viewOnly) {
        if (this.inputHasErrors()) {
          return;
        }

        this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					feedbackData: this.itemData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						feedbackData: this.itemData,
						index: this.editItem.index
					};
				}
				this.$store
					.dispatch("setFeedbackDetails", {
						farmerID: this.farmerID,
						actionObject: actionObject
					})
					.then(() => {
						this.goBackToParent();
					})
					.catch(error => {
						this.$notify({
							title:
								"Failed to " + actionString + " feedback",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			} else {
				this.goBackToParent();
			}
    },
    inputHasErrors() {
      var invalidValue = false;
      if (this.itemData.added_date == "") {
        invalidValue = true;
      }if (this.itemData.added_date.length>10) {
        invalidValue = true;
      }
      if (this.itemData.recommendation == "") {
        invalidValue = true;
      }
      if (this.itemData.feedback == "") {
        invalidValue = true;
      }

      if (invalidValue) {
        this.$notify({
          title: "Error",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: "Please enter valid data for all fields",
        });
      }

      return invalidValue;
    },
    goBackToParent() {
      this.$router.back();
    },
    fetchFeedbackDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchFeedbackDetails", {
					farmerID: this.farmerID,
					itemID: this.editItem.data.id
				})
				.then(() => {
					this.displayLoader = false;
					this.itemData = JSON.parse(
						JSON.stringify(this.currentItem)
					);
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch feedback details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		}
  },

  computed: {
    tableData() {
      return this.$store.state.farmerData.fvrFeedbackData.tables[1].data;
    },
    currentItem() {
			return this.tableData.find(
				item => item.id == this.editItem.data.id
			);
		},
    actionString() {
      if (this.editItem) {
        return "Edit";
      } else {
        return "Add";
      }
    },
  },
  mounted() {
    if (this.editItem) {
      this.fetchFeedbackDetails()
      this.itemData = this.currentItem;
    }
  },
};
</script>
<style lang="scss" scoped></style>
