<template>
	<div>
		<div class="row">
			<div class="col">
				<div slot="header">
					<div class="row">
						<div class="col-12">
							<h1>Task - {{ actionString() }}</h1>
						</div>
					</div>
				</div>
				<div class="pl-lg-4 mt-4">
					<div class="row">
						<div class="col-4 mb-4">
							<label for="" class="">Farmer</label>
							<select
								name="farmer_list"
								id="farmer_list"
								class="form-control"
								v-model="itemData.farmer"
							>
								<option value="" disabled selected>
									Choose Farmer
								</option>
								<option
									v-for="item in farmerList"
									:key="item.id"
									:value="item.id"
								>
									{{ item.name }}
								</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<base-input
								label="Date"
								input-classes="form-control"
								v-model="itemData.task_date"
								type="date"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-8">
							<label for="">Details</label>
							<textarea
								label="Details"
								class="form-control"
								v-model="itemData.task_name"
								type="textarea"
								rows="5"
							/>
						</div>
					</div>
				</div>

				<div class="button-bar text-right mt-8">
					<button
						class="btn btn-danger"
						@click.prevent="$router.back()"
					>
						Cancel
					</button>
					<button
						class="btn btn-success"
						@click.prevent="addEditTask"
					>
						Done
					</button>
				</div>
			</div>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
export default {
	name: "task-add",
	data() {
		return {
			displayLoader: false,
			select: "Select",
			itemData: {
				task_date: "",
				task_name: "",
				farmer: "",
			},
		};
	},
	computed: {
		taskID() {
			return this.$route.params.taskID;
		},
		executiveID() {
			return this.$route.params.executiveID;
		},
		farmerList() {
			return this.$store.state.ExecutiveData.TaskData.farmerList;
		},
	},
	methods: {
		actionString() {
			if (this.taskID === "") {
				return "Add";
			} else {
				return "Edit";
			}
		},
		addEditTask() {
			this.displayLoader = true;

			this.$store
				.dispatch("updateTask", {
					data: this.itemData,
					executiveID: this.executiveID,
					taskID: this.taskID,
				})
				.then(() => {
					this.$router.back();
					this.displayLoader = false;
				})
				.catch(() => {
					var error = "";
					if (this.itemData.farmer === "") {
						error = "Farmer must be selected";
					}
					this.$notify({
						title: "Failed to update task",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
		},
		fetchExeFarmersList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchExeFarmersList", this.executiveID)
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch farmers list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
		},
		fetchTaskDetails() {
			if (this.taskID !== undefined) {
				this.displayLoader = true;
				this.$store
					.dispatch("fetchTaskDetails", {
						executiveID: this.executiveID,
						taskID: this.taskID,
					})
					.then(() => {
						this.itemData = JSON.parse(
							JSON.stringify(
								this.$store.state.ExecutiveData.TaskData
									.taskDetails
							)
						);
						this.displayLoader = false;
					})
					.catch((error) => {
						this.displayLoader = false;
						this.$notify({
							title: "Failed to fetch executive task details",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					});
			}
		},
	},
	mounted() {
		this.fetchTaskDetails();
		this.fetchExeFarmersList();
	},
};
</script>
<style scoped></style>
