<template>
	<div>
		<div class="row">
			<div class="col">
				<div slot="header">
					<div class="row text-center">
						<div class="col-12">
							<h3 class="mb-4">
								{{ actionString }} Farm Practices
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="pl-lg-4">
			<div class="row">
				<div class="col-12">
					<base-input
						label="Farm Practice Name"
						input-classes="form-control"
						v-model="itemData.practice_name"
						:disabled="viewOnly"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-12 form-group">
					<label class="form-control-label">Details:</label>
					<textarea
						class="form-control"
						rows="8"
						@input="watchBullets()"
						v-model="itemData.description"
						:disabled="viewOnly"
					></textarea>
				</div>
			</div>

			<div class="button-bar text-right mt-4">
				<button
					v-if="!viewOnly"
					class="btn btn-danger"
					@click.prevent="goBackToParent()"
				>
					Cancel
				</button>
				<button class="btn btn-success" @click.prevent="performAction">
					Done
				</button>
			</div>

			<loader :displayLoader="displayLoader"></loader>
		</div>
	</div>
</template>
<script>
export default {
	name: "farm-practices-add",
	props: {
		selectedCrop: Number,
		editItem: {
			type: Object,
			default: undefined,
		},
		viewOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			displayLoader: false,
			select: "Select",
			itemData: {
				crop: "",
				practice_name: "",
				description: "• ",
			},
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				if (this.inputHasErrors()) {
					return;
				}

				this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					farmPracticeData: this.itemData,
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						farmPracticeData: this.itemData,
						index: this.editItem.index,
					};
				}
				this.displayLoader = false;
				this.$store
					.dispatch("setFarmingPracticeDetails", actionObject)
					.then(() => {
						this.goBackToParent();
					})
					.catch((error) => {
						this.$notify({
							title:
								"Failed to " + actionString + " farm practice",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			} else {
				this.goBackToParent();
			}
		},
		inputHasErrors() {
			var invalidValue = false;

			if (this.itemData.practice_name == "") {
				invalidValue = true;
			} else if (this.itemData.description == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields",
				});
			}

			return invalidValue;
		},
		watchBullets() {
			// Fragile lol
			var str = this.itemData.details;
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData.details += "• ";
			}
		},
		goBackToParent() {
			this.$router.back();
		},
		selectCrop() {
			this.itemData.crop = this.selectedCrop
		},
	},
	computed: {
		tableData() {
			return this.$store.state.cropData.farmPractices.tableData;
		},
		actionString() {
			if (this.viewOnly) {
				return "View";
			} else if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		},
	},
	watch: {
		selectedCrop() {
			this.selectCrop();
		},
	},
	mounted() {
		if(this.selectedCrop === undefined){
			this.$router.back()
		}
		if (this.editItem) {
			this.itemData = this.editItem.data;
		}
		this.selectCrop();
	},
};
</script>
<style scoped></style>
