<template>
	<div>
		<div class="container-fluid align-items-center">
			<div class="mt-5 w-100 text-center">
				<h1 class="display-4">Farmer Profile Dashboard</h1>
				<base-button
					icon="fa fa-download"
					@click.prevent="downloadReport"
					>Download sample report</base-button
				>
			</div>

			<vue-html2pdf
				:show-layout="false"
				:float-layout="true"
				:enable-download="false"
				:preview-modal="true"
				:paginate-elements-by-height="1400"
				filename="Farmer Report"
				:pdf-quality="2"
				:manual-pagination="false"
				pdf-format="a4"
				pdf-orientation="landscape"
				pdf-content-width="800px"
				:html-to-pdf-options="{ margin: 10 }"
				ref="html2Pdf"
			>
				<section slot="pdf-content">
					<FarmerReport :report="report" />
				</section>
			</vue-html2pdf>

			<div class="row">
				<div
					class="col-sm-12 col-md-12 col-lg-3 align-self-center justify-content-center"
				>
					<div class="btn d-block" @click.prevent="editFarmer">
						<img
							:src="
								farmerProfile.profile_photo
									? farmerProfile.profile_photo
									: $store.state.accountData.profilePicture
							"
							class="rounded-circle my-2"
							width="80px"
						/><br />
						<div>
							Farmer
							{{
								farmerProfile
									? farmerProfile.name
									: "Unknown name"
							}}
						</div>
					</div>
				</div>

				<!-- Header cards -->
				<div
					v-for="(headerCard, cardIndex) in headerCardData"
					class="col-sm-12 col-md-12 col-lg-3"
					:key="cardIndex"
				>
					<header-card
						class="my-5"
						:headerCardData="headerCard"
					></header-card>
				</div>

				<!-- Cards -->
				<div
					class="col-4 text-center p-4 m-0"
					v-for="(subPage, subPageIndex) in subPages"
					:key="subPageIndex + ' page'"
				>
					<div
						class="p-0 btn btn-outline-primary"
						@click.prevent="goToPage(subPage.name)"
					>
						<div class="row align-items-center m-0">
							<div class="col-3 bg-lighter p-0 rounded-left">
								<img
									class="img-fluid w-100 p-sm-1 p-md-1 p-lg-3"
									:src="subPage.image"
								/>
							</div>
							<div class="col-8 p-0">{{ subPage.name }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import FarmerReport from "./FarmerReport";

export default {
	name: "farmer-profile-dashboard",
	components: {
		VueHtml2pdf,
		FarmerReport,
	},
	props: {
		selectedLandID: Number,
		farmerID: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			subPages: [
				{
					name: "Farm Profile",
					image: "./img/farmer section/farm_profile.svg",
				},
				{
					name: "Crop Health Analysis",
					image: "./img/farmer section/crop_health.svg",
				},
				{
					name: "Agri Inputs",
					image: "./img/farmer section/agri_inputs.svg",
				},
				{
					name: "Crop Yield Analysis",
					image: "./img/crops/disease.svg",
				},
				{
					name: "Expenditure",
					image: "./img/farmer section/expenses.png",
				},
				{
					name: "Incidents",
					image: "./img/farmer section/incidents.svg",
				},
				{
					name: "Recommendations",
					image: "./img/farmer section/recommendations.svg",
				},
				{
					name: "Productivity",
					image: "./img/crops/misc.svg",
				},
				{
					name: "Disease History",
					image: "./img/crops/pests.svg",
				},
				{
					name: "Subscriptions",
					image: "./img/farmer section/subscription.png",
				},
				{
					name: "FVR and Feedback",
					image: "./img/crops/experts.svg",
				},
				
			],
			headerCardData: [
				{
					title: "Plants",
					content: "9587",
					icon: "fa fa-leaf",
					iconGradient: "gradient-green",
					changePercent: "13%",
					changePositive: true,
				},

				{
					title: "Land",
					content: "9587",
					icon: "ni ni-chart-pie-35",
					iconGradient: "gradient-green",
					changePercent: "13%",
					changePositive: true,
				},

				{
					title: "Amount",
					content: "9587",
					icon: "ni ni-money-coins",
					iconGradient: "gradient-orange",
					changePercent: "13%",
					changePositive: true,
				},
			],
			displayLoader: false,
		};
	},
	methods: {
		downloadReport() {
			this.$refs.html2Pdf.generatePdf();
		},
		editFarmer() {
			this.$router.push("dashboard/edit-farmer");
		},
		goToPage(page) {
			this.$router.push(
				"/farmers-section/" +
					this.farmerID +
					"/dashboard/" +
					page.toLowerCase().replaceAll(" ", "-")
			);
		},
		fetchFarmerReport() {
			
			this.$store
				.dispatch("fetchFarmerReportData", this.farmerID)
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch farmer report",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					
				});
		},
	},
	computed: {
		farmerProfile() {
			return this.$store.state.farmerData.farmerProfile;
		},
		token() {
			return localStorage.getItem('decoded-token');
		},
		report(){
		return this.$store.state.farmerData.farmerReport.farmerReport[0]
		}
	},
	watch: {
		farmerID() {
			this.$store
				.dispatch("fetchFarmerland", this.farmerID)
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch Land",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
				this.fetchFarmerReport()
		},
		selectedLandID() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchFarmerCrops", this.selectedLandID)
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
	},
	mounted() {
		
		if(this.token === 'executive'){
			this.subPages.push({
					name: "Images",
					image: "./img/executive section/gallery.svg",
				})
		}
		this.$store
			.dispatch("fetchFarmerland", this.farmerID)
			.catch((error) => {
				this.$notify({
					title: "Failed to fetch Land",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
			});
		if (this.selectedLandID != undefined) {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchFarmerCrops", this.selectedLandID)
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		}
			if (this.farmerProfile.id != this.farmerID) {
				this.$store
					.dispatch("fetchFarmerProfile", this.farmerID)
					.catch((error) => {
						this.$notify({
							title: "Failed to fetch farmer profile data",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					});
			}
			this.fetchFarmerReport()
	},
};
</script>
<style scoped></style>
