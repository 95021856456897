var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-4 text-right"},[_c('base-button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.report()}}},[_vm._v(" Report ")])],1)]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-lg-6 mt-3"},[_c('div',{staticClass:"px-5 py-3"},[_c('line-chart-view',{attrs:{"type":"default","chartData":_vm.chartData[0]}})],1)]),_c('div',{staticClass:"col-lg-6 mt-3"},[_c('div',{staticClass:"px-5 py-3"},[_c('line-chart-view',{attrs:{"type":"default","chartData":_vm.chartData[1]}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-lg-6 mt-4"},[_c('card',{attrs:{"shadow":true}},[_c('h4',{staticClass:"mb-3 text-center"},[_vm._v("Health Analysis")]),_c('div',{staticClass:"table-responsive"},[_c('base-table',{staticClass:"table align-items-center table-flush",attrs:{"title":"","thead-classes":"thead-light","tbody-classes":"list","type":"hover","data":_vm.tableData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(row.name))]),_c('td',[_vm._v(_vm._s(row.details))])]}}])},[_c('template',{slot:"columns"},[_c('th',[_vm._v("Sl No")]),_c('th',[_vm._v("Analysis Name")]),_c('th',[_vm._v("Details")])])],2)],1)])],1),_c('div',{staticClass:"col col-lg-6 mt-4"},[_c('card',{attrs:{"shadow":true}},[_c('h4',{staticClass:"mb-3 text-center"},[_vm._v(" Health Predictions/ Possibility of Diseases ")]),_c('div',{staticClass:"table-responsive"},[_c('base-table',{staticClass:"table align-items-center table-flush",attrs:{"title":"","thead-classes":"thead-light","tbody-classes":"list","type":"hover","data":_vm.tableData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(row.name))]),_c('td',[_vm._v(_vm._s(row.details))])]}}])},[_c('template',{slot:"columns"},[_c('th',[_vm._v("Sl No")]),_c('th',[_vm._v("Analysis Name")]),_c('th',[_vm._v("Details")])])],2)],1)])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-lg-6 mt-4"},[_c('card',{attrs:{"shadow":true}},[_c('h4',{staticClass:"mb-3 text-center"},[_vm._v(" Health Analysis based recommendations ")]),_c('div',{staticClass:"table-responsive"},[_c('base-table',{staticClass:"table align-items-center table-flush",attrs:{"title":"","thead-classes":"thead-light","tbody-classes":"list","type":"hover","data":_vm.tableData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(row.name))]),_c('td',[_vm._v(_vm._s(row.details))])]}}])},[_c('template',{slot:"columns"},[_c('th',[_vm._v("Sl No")]),_c('th',[_vm._v("Analysis Name")]),_c('th',[_vm._v("Details")])])],2)],1)])],1),_c('div',{staticClass:"col col-lg-6 mt-4"},[_c('card',{attrs:{"shadow":true}},[_c('h4',{staticClass:"mb-3 text-center"},[_vm._v(" Other Health recommendations ")]),_c('div',{staticClass:"table-responsive"},[_c('base-table',{staticClass:"table align-items-center table-flush",attrs:{"title":"","thead-classes":"thead-light","tbody-classes":"list","type":"hover","data":_vm.tableData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(row.name))]),_c('td',[_vm._v(_vm._s(row.details))])]}}])},[_c('template',{slot:"columns"},[_c('th',[_vm._v("Sl No")]),_c('th',[_vm._v("Analysis Name")]),_c('th',[_vm._v("Details")])])],2)],1)])],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-8"},[_c('h1',{staticClass:"display-4"},[_vm._v("Crop Health Analysis")])])}]

export { render, staticRenderFns }