<template>
	<div>
		<!-- Autogenerated file -->
		<DIV id="page_1">
			<DIV id="id1_1">
				<hr class="m-0" />
				<h1 class="p0 text-center text-black">
					{{ report && report.name }}
				</h1>
				<p class="text-small pt-4 p_21">
					{{ report && report.address }} (Farmer- Personal
					information- Address)
				</p>
				<p class="text-small p_21">
					{{ report && report.mobile_number }} (Farmer- Personal
					information- Mobile)
				</p>
				<p class="text-small p_21">
					{{ report && report.email }} (Farmer- Personal information-
					Email)
				</p>
			</DIV>

			<DIV id="id1_2">
				<div
					v-for="(land, idx) in report && report.lands"
					:key="idx + 1"
				>
					<h2 class="p0 text-center py-3 mt-2 text-black">
						{{ land && land.name }}
					</h2>
					<h2 class="text-green py-3 mt-2">Soil Report</h2>
					<table>
						<thead>
							<tr>
								<th scope="col">Sl No</th>
								<th scope="col">Indicator</th>
								<th scope="col">Quantity</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(soil, idx) in land && land.soil_report"
								:key="idx + 2"
							>
								<th scope="row">{{ idx + 1 }}</th>
								<td>{{ soil.indicator }}</td>
								<td>{{ soil.quantity }}</td>
							</tr>
						</tbody>
					</table>
					<h2 class="text-green py-3 mt-2">Soil & Land Info</h2>
					<p class="mb-3">{{ land && land.soil_and_land_info[0].details }}</p>
					<h2 class="text-green py-3 mt-2">Water Info</h2>
					<p>{{ land && land.water_info[0].details }}</p>
					<div v-for="(item ,idx) in land && land.crops" :key="idx+3">
						<h2 class="p0 text-center py-3 text-black">{{item.name}}</h2>
						<h2 class="text-green py-3 mt-2">Local Varieties</h2>
						<table>
							<thead>
								<tr>
									<th scope="col">Sl No</th>
									<th scope="col">Variety</th>
									<th scope="col">Count</th>
									<th scope="col">Crop age</th>
									<th scope="col">Supporting crop</th>
									<th scope="col">Crop height</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(local, idx) in item &&
									item.local_verity"
									:key="idx + 2"
								>
									<th scope="row">{{ idx + 1 }}</th>
									<td>{{ local.variety_name }}</td>
									<td>{{ local.count }}</td>
									<td>{{ local.crop_age }}</td>
									<td>{{ local.supporting_crop }}</td>
									<td>{{ local.crop_height }}</td>
								</tr>
							</tbody>
						</table>
						<h2 class="text-green py-3 mt-2">Improved Varieties</h2>
						<table>
							<thead>
								<tr>
									<th scope="col">Sl No</th>
									<th scope="col">Variety</th>
									<th scope="col">Count</th>
									<th scope="col">Crop age</th>
									<th scope="col">Supporting crop</th>
									<th scope="col">Crop height</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(local, idx) in item &&
									item.improved_verity"
									:key="idx + 2"
								>
									<th scope="row">{{ idx + 1 }}</th>
									<td>{{ local.variety_name }}</td>
									<td>{{ local.count }}</td>
									<td>{{ local.crop_age }}</td>
									<td>{{ local.supporting_crop }}</td>
									<td>{{ local.crop_height }}</td>
								</tr>
							</tbody>
						</table>
						<h2 class="text-green py-3 mt-2">Planting Methods</h2>
						<p>{{item.planting_method[0].details}}</p>
						<h2 class="text-green py-3 mt-2">Farm Practices</h2>
						<p>{{item.farm_practices[0].details}}</p>
						<h2 class="text-green py-3 mt-2">Disease History</h2>
						<table class="">
							<thead>
								<tr>
									<th scope="col">Sl No</th>
									<th scope="col">Date</th>
									<th scope="col">Name of disease</th>
									<th scope="col">Precautions</th>
									<th scope="col">Details</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(data, idx) in item &&
									item.disease_history"
									:key="idx + 2"
								>
									<th scope="row">{{ idx + 1 }}</th>
									<td>{{ data.date }}</td>
									<td>{{ data.name }}</td>
									<td>{{ data.precautions }}</td>
									<td>{{ data.details }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</DIV>
		</DIV>
	</div>
</template>
<script>
export default {
	name: "farmer-report",
	props: {
		report: {
			type: Object,
		},
	},
	mounted() {
	},
};
</script>
<style scoped>
table {
	width: 90%;
}
table,
th,
td {
	border: 0.5px solid;
	padding: 10px;
}
#page_1 {
	position: relative;
	overflow: hidden;
	padding: 0px;
	border: none;
	width: 100%;
}
#page_1 #id1_1 {
	border: none;
	margin: 0px 0px 0px 0px;
	padding: 0px;
	border: none;
	width: 100%;
	overflow: hidden;
}
#page_1 #id1_2 {
	border: none;
	margin: 9px 0px 0px 0px;
	padding: 0px;
	border: none;
	width: 794px;
	overflow: hidden;
}
#page_1 #id1_2 #id1_2_1 {
	float: left;
	border: none;
	margin: 9px 0px 3px 3px;
	padding: 0px;
	border: none;
	width: 242px;
	overflow: hidden;
}
#page_1 #id1_2 #id1_2_2 {
	float: left;
	border: none;
	margin: 9px 0px 0px 0px;
	padding: 0px;
	border: none;
	width: 87px;
	overflow: hidden;
}
#page_1 #id1_2 #id1_2_3 {
	float: left;
	border: none;
	margin: 9px 0px 0px 0px;
	padding: 0px;
	border: none;
	width: 80px;
	overflow: hidden;
}
#page_1 #id1_2 #id1_2_4 {
	float: left;
	border: none;
	margin: 9px 0px 0px 0px;
	padding: 0px;
	border: none;
	width: 90px;
	overflow: hidden;
}
#page_1 #id1_2 #id1_2_5 {
	float: left;
	border: none;
	margin: 0px 0px 0px 0px;
	padding: 0px;
	border: none;
	width: 295px;
	overflow: hidden;
}
#page_1 #id1_3 {
	border: none;
	margin: 36px 0px 0px 96px;
	padding: 0px;
	border: none;
	width: 698px;
	overflow: hidden;
}

#page_1 #p1dimg1 {
	position: absolute;
	top: 212px;
	left: 96px;
	z-index: -1;
	width: 588px;
	height: 403px;
}
#page_1 #p1dimg1 #p1img1 {
	width: 588px;
	height: 403px;
}

#page_1 #p1inl_img1 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img2 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img3 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img4 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img5 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img6 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img7 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img8 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img9 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img10 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img11 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img12 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img13 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img14 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img15 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img16 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img17 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img18 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img19 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img20 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img21 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img22 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img23 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img24 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img25 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img26 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img27 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img28 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img29 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img30 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img31 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img32 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img33 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img34 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img35 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img36 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img37 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img38 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img39 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img40 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img41 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img42 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img43 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img44 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img45 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img46 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img47 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img48 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img49 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img50 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img51 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img52 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img53 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img54 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img55 {
	position: relative;
	width: 1px;
	height: 21px;
}
#page_1 #p1inl_img56 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img57 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img58 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img59 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img60 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img61 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img62 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img63 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img64 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img65 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img66 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img67 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img68 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img69 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img70 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img71 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img72 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img73 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img74 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img75 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img76 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img77 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img78 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img79 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img80 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img81 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img82 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img83 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img84 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img85 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img86 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img87 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img88 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img89 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img90 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img91 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img92 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img93 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img94 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img95 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img96 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img97 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img98 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img99 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img100 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img101 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img102 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img103 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img104 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img105 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img106 {
	position: relative;
	width: 1px;
	height: 24px;
}
#page_1 #p1inl_img107 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img108 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img109 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img110 {
	position: relative;
	width: 1px;
	height: 23px;
}
#page_1 #p1inl_img111 {
	position: relative;
	width: 1px;
	height: 22px;
}
#page_1 #p1inl_img112 {
	position: relative;
	width: 1px;
	height: 22px;
}

#page_2 {
	position: relative;
	overflow: hidden;
	margin: 100px 0px 171px 96px;
	padding: 0px;
	border: none;
	width: 698px;
}

#page_3 {
	position: relative;
	overflow: hidden;
	margin: 100px 0px 146px 96px;
	padding: 0px;
	border: none;
	width: 698px;
}

#page_4 {
	position: relative;
	overflow: hidden;
	margin: 100px 0px 939px 96px;
	padding: 0px;
	border: none;
	width: 698px;
}

.ft0 {
	font: bold 19px "Calibri";
	text-decoration: underline;
	line-height: 23px;
}
.ft1 {
	font: bold 15px "Calibri";
	line-height: 18px;
}
.ft2 {
	font: bold 15px "Calibri";
	margin-left: 11px;
	line-height: 18px;
}
.ft3 {
	font: 14px "Calibri";
	line-height: 17px;
}
.ft4 {
	font: 15px "Calibri";
	margin-left: 4px;
	line-height: 18px;
}
.ft5 {
	font: 15px "Calibri";
	line-height: 18px;
}
.ft6 {
	font: italic 15px "Calibri";
	text-decoration: underline;
	line-height: 18px;
}
.ft7 {
	font: 15px "Calibri";
	margin-left: 3px;
	line-height: 18px;
}
.ft8 {
	font: bold 15px "Calibri";
	margin-left: 12px;
	line-height: 18px;
}
.ft9 {
	font: 15px "Calibri";
	text-decoration: underline;
	line-height: 18px;
}
.ft10 {
	font: bold 15px "Calibri";
	margin-left: 13px;
	line-height: 18px;
}
.ft11 {
	font: bold 14px "Calibri";
	line-height: 17px;
}
.ft12 {
	font: 14px "Calibri";
	margin-left: 3px;
	line-height: 25px;
}
.ft13 {
	font: 14px "Calibri";
	line-height: 25px;
}
.ft14 {
	font: 15px "Calibri";
	margin-left: 6px;
	line-height: 18px;
}
.ft15 {
	font: 15px "Calibri";
	margin-left: 5px;
	line-height: 18px;
}
.ft16 {
	font: bold 15px "Calibri";
	margin-left: 16px;
	line-height: 18px;
}

.p0 {
	text-align: right;
	padding-right: 165px;
	margin-top: 0px;
	margin-bottom: 0px;
}
.p1 {
	text-align: left;
	padding-left: 267px;
	margin-top: 12px;
	margin-bottom: 0px;
}
.p2 {
	text-align: left;
	padding-left: 120px;
	margin-top: 47px;
	margin-bottom: 0px;
}
.p3 {
	text-align: left;
	padding-left: 96px;
	margin-top: 32px;
	margin-bottom: 0px;
}
.p4 {
	text-align: left;
	padding-left: 96px;
	margin-top: 12px;
	margin-bottom: 0px;
}
.p5 {
	text-align: right;
	padding-right: 46px;
	margin-top: 0px;
	margin-bottom: 0px;
}
.p6 {
	text-align: left;
	padding-left: 101px;
	margin-top: 16px;
	margin-bottom: 0px;
}
.p7 {
	text-align: left;
	padding-left: 101px;
	margin-top: 8px;
	margin-bottom: 0px;
}
.p8 {
	text-align: left;
	padding-left: 101px;
	margin-top: 7px;
	margin-bottom: 0px;
}
.p9 {
	text-align: right;
	padding-right: 19px;
	margin-top: 0px;
	margin-bottom: 0px;
}
.p10 {
	text-align: left;
	margin-top: 11px;
	margin-bottom: 0px;
}
.p11 {
	text-align: left;
	margin-top: 2px;
	margin-bottom: 0px;
}
.p12 {
	text-align: left;
	margin-top: 1px;
	margin-bottom: 0px;
}
.p13 {
	text-align: left;
	margin-top: 3px;
	margin-bottom: 0px;
}
.p14 {
	text-align: right;
	padding-right: 20px;
	margin-top: 0px;
	margin-bottom: 0px;
}
.p15 {
	text-align: right;
	padding-right: 152px;
	margin-top: 0px;
	margin-bottom: 0px;
}
.p16 {
	text-align: left;
	padding-left: 66px;
	margin-top: 0px;
	margin-bottom: 0px;
}
.p_21 {
	margin-top: 0px;
	margin-bottom: 0px;
	text-align: left;
	font-size: 12px;
}
.p17 {
	text-align: left;
	margin-top: 0px;
	margin-bottom: 0px;
}
.p18 {
	text-align: left;
	margin-top: 12px;
	margin-bottom: 0px;
}
.p19 {
	text-align: left;
	padding-left: 24px;
	margin-top: 0px;
	margin-bottom: 0px;
}
.p20 {
	text-align: left;
	padding-left: 24px;
	margin-top: 42px;
	margin-bottom: 0px;
}
.p21 {
	text-align: left;
	padding-right: 166px;
	margin-top: 12px;
	margin-bottom: 0px;
}
.p22 {
	text-align: left;
	padding-right: 464px;
	margin-top: 12px;
	margin-bottom: 0px;
}
.p23 {
	text-align: left;
	margin-top: 10px;
	margin-bottom: 0px;
}
.p24 {
	text-align: left;
	padding-left: 24px;
	margin-top: 61px;
	margin-bottom: 0px;
}
.p25 {
	text-align: left;
	margin-top: 13px;
	margin-bottom: 0px;
}
.p26 {
	text-align: left;
	padding-right: 96px;
	margin-top: 11px;
	margin-bottom: 0px;
}
.p27 {
	text-align: left;
	padding-right: 96px;
	margin-top: 13px;
	margin-bottom: 0px;
}
.p28 {
	text-align: left;
	padding-right: 97px;
	margin-top: 14px;
	margin-bottom: 0px;
}
.p29 {
	text-align: left;
	padding-right: 97px;
	margin-top: 13px;
	margin-bottom: 0px;
}
.p30 {
	text-align: justify;
	padding-right: 97px;
	margin-top: 14px;
	margin-bottom: 0px;
}
.p31 {
	text-align: justify;
	padding-right: 96px;
	margin-top: 12px;
	margin-bottom: 0px;
}
</style>
