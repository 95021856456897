<template>
<div>
	<div class="row">
		<div class="col"> 
			<h2>Crop Calendar</h2>

			{{ selectedCrop }} crop calendar image here
		</div>
	</div>
</div>
</template>
<script>

export default {
	name: 'crop-calendar',
    props: {
		selectedCrop: Number
	},
	data() {
		return {
			
		}
	},
	methods: {
		
	},
    computed: {

    },
    mounted() {

    }
}
</script>
<style scoped>
</style>
