<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col-8">
						<h1 class="display-4">Crop Health Analysis</h1>
						<!-- No add button as crop health analysis data is supposed to be view only -->
					</div>
					<div class="col-4 text-right">
						<base-button
							type="button"
							class="btn btn-danger"
							@click.prevent="report()"
						>
							Report
						</base-button>
					</div>
				</div>

				<!--Graphs -->
				<div class="row mb-4">
					<div class="col-lg-6 mt-3">
						<div class="px-5 py-3">
							<line-chart-view
								type="default"
								:chartData="chartData[0]"
							></line-chart-view>
						</div>
					</div>
					<div class="col-lg-6 mt-3">
						<div class="px-5 py-3">
							<line-chart-view
								type="default"
								:chartData="chartData[1]"
							></line-chart-view>
						</div>
					</div>
				</div>

				<!-- Tables -->
				<div class="row">
					<div class="col col-lg-6 mt-4">
						<card :shadow="true">
							<h4 class="mb-3 text-center">Health Analysis</h4>
							<div class="table-responsive">
								<base-table
									class="table align-items-center table-flush"
									title=""
									thead-classes="thead-light"
									tbody-classes="list"
									type="hover"
									:data="tableData"
								>
									<template slot="columns">
										<th>Sl No</th>
										<th>Analysis Name</th>
										<th>Details</th>
									</template>
									<template slot-scope="{ row, index }">
										<td>{{ index + 1 }}</td>
										<td>{{ row.name }}</td>
										<td>{{ row.details }}</td>
									</template>
								</base-table>
							</div>
						</card>
					</div>
					<div class="col col-lg-6 mt-4">
						<card :shadow="true">
							<h4 class="mb-3 text-center">
								Health Predictions/ Possibility of Diseases
							</h4>
							<div class="table-responsive">
								<base-table
									class="table align-items-center table-flush"
									title=""
									thead-classes="thead-light"
									tbody-classes="list"
									type="hover"
									:data="tableData"
								>
									<template slot="columns">
										<th>Sl No</th>
										<th>Analysis Name</th>
										<th>Details</th>
									</template>
									<template slot-scope="{ row, index }">
										<td>{{ index + 1 }}</td>
										<td>{{ row.name }}</td>
										<td>{{ row.details }}</td>
									</template>
								</base-table>
							</div>
						</card>
					</div>
				</div>
				<div class="row">
					<div class="col col-lg-6 mt-4">
						<card :shadow="true">
							<h4 class="mb-3 text-center">
								Health Analysis based recommendations
							</h4>
							<div class="table-responsive">
								<base-table
									class="table align-items-center table-flush"
									title=""
									thead-classes="thead-light"
									tbody-classes="list"
									type="hover"
									:data="tableData"
								>
									<template slot="columns">
										<th>Sl No</th>
										<th>Analysis Name</th>
										<th>Details</th>
									</template>
									<template slot-scope="{ row, index }">
										<td>{{ index + 1 }}</td>
										<td>{{ row.name }}</td>
										<td>{{ row.details }}</td>
									</template>
								</base-table>
							</div>
						</card>
					</div>
					<div class="col col-lg-6 mt-4">
						<card :shadow="true">
							<h4 class="mb-3 text-center">
								Other Health recommendations
							</h4>
							<div class="table-responsive">
								<base-table
									class="table align-items-center table-flush"
									title=""
									thead-classes="thead-light"
									tbody-classes="list"
									type="hover"
									:data="tableData"
								>
									<template slot="columns">
										<th>Sl No</th>
										<th>Analysis Name</th>
										<th>Details</th>
									</template>
									<template slot-scope="{ row, index }">
										<td>{{ index + 1 }}</td>
										<td>{{ row.name }}</td>
										<td>{{ row.details }}</td>
									</template>
								</base-table>
							</div>
						</card>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "health-analysis",
	components: {},
	props: {
		farmerID: {
			type: String,
			default: undefined,
		},
		category: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {};
	},
	methods: {
		/* No add data function as crop health analysis data is supposed to be view only */
		report() {
			this.$router.push(
				"/farmers-section/" +
					this.farmerID +
					"/dashboard/crop-health-analysis/report"
			);
		},
	},
	computed: {
		tableHeaders() {
			return this.$store.state.farmerData.cropHealthAnalysisData.tableHeaders;
		},
		tableData() {
			return this.$store.state.farmerData.cropHealthAnalysisData.data;
		},
		chartData() {
			return this.$store.state.farmerData.cropHealthAnalysisData.chartData;
		},
	},
	mounted() {},
};
</script>

